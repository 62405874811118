<template>
  <div
    v-if="organization && organization.externalIdentityProviderData"
    class="mt-7 flex-col gap-4"
  >
    <btn
      :size="BtnSize.LG"
      :variant="BtnVariant.OUTLINED"
      :color="BtnColor.PRIMARY"
      class="w-full mt-7"
      @click="handleClick"
    >
      <img
        v-if="organization.iconUrl"
        :src="organization.iconUrl"
        alt="Organization icon"
        class="h-5 w-5 mr-2 inline-block"
      />
      {{
        organization.externalIdentityProviderData?.loginButtonLabel ||
        'Sign with SSO'
      }}
    </btn>
  </div>
</template>
<script lang="ts" setup>
import { Btn, BtnSize, BtnColor, BtnVariant } from '~/components/btn';
import { OAuthProvider, signInWithPopup, UserCredential } from 'firebase/auth';
import { useFirebase } from '~/composables/use-firebase';
import { useOrganizationStore } from '~/store/organization';
import { storeToRefs } from 'pinia';
import { useAuthUserStore } from '~/store/auth-user';
import { apiGetLoggedInUserInfo, apiLogin } from '~/api/auth';
import { useAccountStore } from '~/store/account';
import { FirebaseError } from 'firebase/app';
import { navigateTo } from '#imports';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface ExtendedUserCredential extends UserCredential {
  _tokenResponse?: {
    rawUserInfo: string;
  };
}

const emit = defineEmits<{
  linkAccounts: [];
  error: [err: unknown];
}>();

const route = useRoute();
const { auth } = useFirebase();

const authUserStore = useAuthUserStore();
const accountStore = useAccountStore();
const { token } = storeToRefs(authUserStore);
const organizationStore = useOrganizationStore();
const { organization } = storeToRefs(organizationStore);

const login = async (
  idToken: string,
  rawUserInfo?: Record<string, unknown>,
) => {
  if (!organization.value) throw new Error('Unknown organization');
  const loginResponse = await apiLogin(
    idToken,
    organization.value.slug,
    rawUserInfo,
  );
  token.value = loginResponse.token;
  authUserStore.authUser = await apiGetLoggedInUserInfo();
  await accountStore.setCurrentAccount();

  const redirect = getFirstQueryParam(route.query.redirect);

  if (organization.value.isCoOp) {
    navigateTo('/business-selection');
  } else {
    navigateTo(redirect ?? '/');
  }
};

const signInWithProvider = async () => {
  if (!organization.value?.externalIdentityProviderData?.providerId) {
    throw new Error('External identity provider id not found');
  }
  const provider = new OAuthProvider(
    organization.value.externalIdentityProviderData.providerId,
  );

  try {
    const result: ExtendedUserCredential = await signInWithPopup(
      auth,
      provider,
    );

    const rawUserInfo = JSON.parse(
      // eslint-disable-next-line no-underscore-dangle
      result._tokenResponse?.rawUserInfo || '{}',
    );

    const idToken = await result.user.getIdToken();
    await login(idToken, rawUserInfo);
  } catch (err: unknown) {
    if (
      err instanceof FirebaseError &&
      err.code === 'auth/account-exists-with-different-credential'
    ) {
      const credential = await OAuthProvider.credentialFromError(err);
      if (credential) {
        authUserStore.setCredentialToLinkOnLogin(credential);
        emit('linkAccounts');
        return;
      }
    }

    emit('error', err);
  }
};

const handleClick = () =>
  useLoader({
    action: signInWithProvider,
    onError(error: any) {
      emit('error', error);
    },
  });
</script>
